$(function () {
  const $posters = $(".posters");
  for (let index = 1; index <= 48; index++) {
    const figure = document.createElement("figure");
    const img = document.createElement("img");
    img.src = `./images/${index}.jpg`;
    img.alt = "WONG LIP CHIN";
    $(img).appendTo(figure);
    $(figure).appendTo($posters);
  }

  $posters.slick({
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    nextArrow: "button.next",
    prevArrow: "button.prev",
    speed: 2000,
  });
});
